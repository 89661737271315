export const setupLocale = async (locale?: string) => {
  const file = await import(`./locales/${locale || 'fr'}.json`);
  return file.default;
};

export const getLanguages = () => [
  {
    id: 'fr',
    name: 'Français',
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    id: 'en',
    name: 'English',
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];
