import Head from 'next/head';
import { useTranslations } from 'next-intl';
import { CONSTANTS } from '../config';

interface Props {
  removeBowldPrefix?: boolean;
  title?: string;
  sharePreview?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
}

export default function MetaHead({
  title,
  sharePreview,
  removeBowldPrefix,
}: Props) {
  const t = useTranslations();

  const metaTitle = title
    ? `${title}${removeBowldPrefix ? '' : ` | ${CONSTANTS.APP_NAME}`}`
    : CONSTANTS.APP_NAME;
  const metaOgTitle = sharePreview?.title
    ? `${removeBowldPrefix ? '' : `${CONSTANTS.APP_NAME} - `}${
        sharePreview.title
      }`
    : metaTitle;
  const metaDescription =
    sharePreview?.description || t('global.bowldOneLiner');
  const metaImageUrl =
    sharePreview?.imageUrl || CONSTANTS.MEDIAS.defaultSharePreviewUrl;

  return (
    <Head>
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaOgTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImageUrl} />
    </Head>
  );
}
